import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../TransactionsTypes';
import UpdateParticipantBrokerDetailCommand from '../../Common/Models/UpdateParticipantBrokerDetailCommand';
import { ParticipantBrokerDetail } from '../../Common/CommonSharedExports';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration';

@NeoModel
export default class BrokerDetailsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private tradeApiClient = AppService.get(Types.Transactions.ApiClients.TradeApiClient)) {

        super(taskRunner);
    }

    public brokerDetails: UpdateParticipantBrokerDetailCommand | null = null;
    public showBrokerDetailsClearModal: boolean = false;

    public async initialise() {
        const result = await this.taskRunner.waitFor(this.tradeApiClient.getParticipantBrokerDetails());
        const command = new UpdateParticipantBrokerDetailCommand();
        if (result.data) {
            command.participantBrokerDetail = ParticipantBrokerDetail.fromJSObject<ParticipantBrokerDetail>(result.data);
        } else {
            command.participantBrokerDetail = new ParticipantBrokerDetail();
        }
        command.markOld();

        this.brokerDetails = command;
    }

    public saveBrokerDetails() {
        this.taskRunner.run(async () => {
            const result = await this.tradeApiClient.updateParticipantBrokerDetails(this.brokerDetails!.toJSObject());
            this.brokerDetails!.participantBrokerDetail.update(result.data);

            this.notifications.addSuccess("Broker details", "Broker details updated successfully.", NotificationDuration.Standard);
        });
    }

    public clearParticipantBrokerDetails(participantBrokerDetailId: number) {
        this.taskRunner.run(async () => {
            await this.tradeApiClient.deactivateParticipantBrokerDetails(participantBrokerDetailId);
            this.brokerDetails!.participantBrokerDetail = new ParticipantBrokerDetail();
            this.showBrokerDetailsClearModal = false;
            this.notifications.addSuccess("Broker details", "Broker details have been removed successfully", NotificationDuration.Standard);
        })

    }
}