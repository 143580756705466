import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import BrokerDetailsVM from './BrokerDetailsVM';
import { observer } from 'mobx-react';
import { BrokerDetailsComponent } from '../../Common/Components/BrokerDetails';

@observer
export default class BrokerDetailsView extends Views.ViewBase<BrokerDetailsVM> {

    constructor(props: unknown) {
        super("Broker Details", BrokerDetailsVM, props);
    }

    public get headerTitle() {
        return "My Profile";
    }

    public render() {
        const brokerDetails = this.viewModel.brokerDetails;
        const participantBrokerDetails = brokerDetails?.participantBrokerDetail;

        return (
            <div className="more-padding">
                <h2>Broker Account Details</h2>

                <p className="my-4">
                    We need your broker account information in order to transfer shares to you once your trade has been completed. Please complete or update the details below:
                </p>

                {brokerDetails &&
                    <div className="row">
                        <div className="col-xl-8 col-xxl-7">
                            <Neo.Form model={brokerDetails} onSubmit={() => this.viewModel.saveBrokerDetails()}>
                                <BrokerDetailsComponent command={brokerDetails} />

                                <div className="text-right">
                                    {
                                        <div>
                                            <Neo.Button className="btn-150 mt-4" isSubmit disabled={!brokerDetails.isDirty || !brokerDetails.isValid}>Save</Neo.Button>
                                            <Neo.Button className="btn-150 mt-4 ml-4" variant='danger' onClick={() => this.viewModel.showBrokerDetailsClearModal = true}>Clear</Neo.Button>
                                        </div>
                                    }
                                </div>
                            </Neo.Form>
                        </div>

                        <Neo.Modal title="Clear Broker Details" bind={this.viewModel.meta.showBrokerDetailsClearModal}
                            closeButton={{ text: "Cancel", variant: "danger", isOutline: true }}
                            acceptButton={{ text: "Accept", variant: "success", onClick: () => this.viewModel.clearParticipantBrokerDetails(participantBrokerDetails!.participantBrokerDetailId) }}>
                            Note: This action will clear the existing broker details and they will not be available for any future trading. If broker details are required for future trades, they can be re-captured.
                        </Neo.Modal>
                    </div>}
            </div>
        );

    }
}