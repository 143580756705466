import React from 'react';
import { NeoGrid, Neo } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../TransactionsTypes';
import { ITaskRunner, ModalUtils } from '@singularsystems/neo-core';
import { observer } from 'mobx-react';
import { ScreenSize } from '../../../../../App/Services/AppLayout';
import { EditTradeBrokerDetailsModal } from '../../../../Common/Components/BrokerDetails';
import { TradeDetailComponent } from './TradeDetailComponent';
import TradeRequestLookup from '../../../Models/Trading/Lookups/TradeRequestLookup';
import { IPendingTradeRequestComponentProps, PendingTradeRequestsComponentModel } from './PendingTradeRequestsComponentModel';
import { TradeRequestHiddenFields } from './TradeRequestHiddenFields';

interface IPendingTradeRequestsComponentProps {
    taskRunner: ITaskRunner;
    options?: IPendingTradeRequestComponentProps;
    viewModel?: PendingTradeRequestsComponentModel;
}

@observer
export class PendingTradeRequestsComponent extends React.Component<IPendingTradeRequestsComponentProps> {

    private viewModel = this.props.viewModel ?? new PendingTradeRequestsComponentModel(this.props.options!);
    private layout = AppService.get(Types.Shared.Services.AppLayout);
    
    private cancelTrade(item: TradeRequestLookup) {

        if (this.viewModel.portfolioService.appData!.settings!.allowTradeCancellation) {
         
            let message = 
                <>Are you sure you want to cancel this trade? 
                {item.tradeRequestLinkId && 
                <div className="mt-2">
                    Please note, this is a linked trade. All related trades will be cancelled.
                </div>}
                </>;

            ModalUtils.showYesNo("Cancel", message, () => this.viewModel.cancelTrade(item))
        } else {

            ModalUtils.showMessage("Cancel", "Please contact an administrator to cancel your trade.");
        }
    }

    public render() {
        const viewModel = this.viewModel;
        const tradeRequests = viewModel.portfolioService.appData?.pendingTradeRequests;
        const hideExpand = this.layout.currentScreenSize >= ScreenSize.Medium

        return (
            <div>
                {tradeRequests && tradeRequests.length > 0 &&
                <div className="portfolio-group-container">
                    <div className="portfolio-group-header">
                        <div className="portfolio-group-title">
                            Pending Trades
                        </div>
                        You will receive email notifications when there is activity on your trades.
                    </div>
                    
                    <div className="portfolio-group-body">
                        <Neo.Loader show={viewModel.portfolioService.pendingTradesTask.isBusy}>
                            <NeoGrid.Grid items={tradeRequests} collapseAll={hideExpand} className={hideExpand ? "pad-left-cells" : ""}>
                                {(item, meta) => (
                                    <NeoGrid.RowGroup expandProperty={meta.isExpanded}>
                                        <NeoGrid.Row>
                                            <NeoGrid.Column display={meta.tradeRequestId} label="Ref No.">
                                                {item.tradeRequestLinkId && <i className="fa fa-link fa-sm ml-1" data-tip="This is a linked trade."></i>}
                                            </NeoGrid.Column>
                                            <NeoGrid.Column label="Placed" display={meta.createdOn} hideBelow="xl" />
                                            <NeoGrid.Column label="Scheme" display={meta.incentiveSchemeName} className="text-heading" />
                                            <NeoGrid.Column display={meta.statusText} className="grid-highlight" hideBelow="xxl" />
                                            <NeoGrid.Column label="Trade Instruction" display={meta.type} hideBelow="xl" />
                                            <NeoGrid.Column label="Units" display={meta.totalQuantity} hideBelow="md" />
                                            <NeoGrid.Column label={"Estimated\nmarket price"} display={meta.price} numProps={{formatString: `${item.currencySymbol} #,##0.00######;(${item.currencySymbol}#,##0.00######)`}} hideBelow="xl" 
                                                headerTooltip="Share price at the time of calculation - note this is likely to be a 15 min delayed price and the actual, final price achieved on the market will be different"/>
                                            <NeoGrid.Column label={"Estimated\ngross value"} display={meta.grossValue} numProps={{currencySymbol: item.currencySymbol}} hideBelow="md" 
                                                headerTooltip="Number of units x estimated share price (before any costs). This value can change based on the final price achieved on market or for the scheme."/>
                                            <NeoGrid.Column label={"Award\ncosts/income"} display={meta.costs} numProps={{currencySymbol: item.currencySymbol}} hideBelow="md" />
                                            <NeoGrid.Column label={"Estimated\nprofit"} display={meta.profit} numProps={{currencySymbol: item.currencySymbol}} hideBelow="md" className="grid-highlight" cellClassName="bold" 
                                                headerTooltip="Potential profit (gross value - award costs) at the estimated market price for units traded, before tax and any transaction costs. "/>
                                            <NeoGrid.ButtonColumn>
                                                {item.canUpdateBrokerDetails &&
                                                    <Neo.Button 
                                                        icon="edit" 
                                                        size="sm" 
                                                        className="btn-action"
                                                        data-tip="Update broker details"
                                                        onClick={() => viewModel.beginEditBrokerDetails(item)}>
                                                    </Neo.Button>}
                                                {item.canCancel &&
                                                    <Neo.Button
                                                        icon="times"
                                                        size="sm"
                                                        className="btn-action"
                                                        onClick={() => this.cancelTrade(item) }>
                                                        Cancel
                                                    </Neo.Button>}
                                                {viewModel.showTradeDetailsButton &&
                                                     <Neo.Button size="sm" variant="info" icon="info" onClick={() => viewModel.showTradeRequest(item)} />}
                                            </NeoGrid.ButtonColumn>
                                        </NeoGrid.Row>
                                        <NeoGrid.ChildRow>
                                            <Neo.GridLayout>
                                                <TradeRequestHiddenFields tradeRequest={item} />
                                            </Neo.GridLayout>
                                        </NeoGrid.ChildRow>
                                    </NeoGrid.RowGroup>
                                )}
                            </NeoGrid.Grid>
                        </Neo.Loader>
                    </div>

                    <div className="portfolio-group-footer">
                        <br/>
                    </div>
                </div>}

                {viewModel.editingTrade && <EditTradeBrokerDetailsModal 
                    bind={viewModel.editingTrade.meta.editingBrokerDetails} 
                    onSave={() => viewModel.saveBrokerDetails()} />}

                <Neo.Modal title="Trade details" size="xl" bindModel={viewModel.meta.tradeRequestWithTransactions}>
                    {(tradeRequest: TradeRequestLookup) => (
                        <TradeDetailComponent tradeRequestLookup={tradeRequest} isInModal taskRunner={this.props.taskRunner} />
                    )}
                </Neo.Modal>
            </div>
        )
    }
}