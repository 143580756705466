import { AppService } from './Services/AppService';
import { appModule } from './STParticipantAppModule';
import { AppServices, Misc } from '@singularsystems/neo-core';
import { NeoReactModule } from '@singularsystems/neo-react'
import { NotificationServiceModule } from '@singularsystems/neo-notifications';

import { clientsAppModule } from '../Clients/Participants/ClientsModule';
import { awardsAppModule } from '../Awards/ParticipantsApp/AwardsModule';
import { identityAppModule } from '../Identity/ParticipantsApp/IdentityModule';
import { transactionsAppModule } from '../Transactions/ParticipantsApp/TransactionsModule';
import { participantsAppModule } from '../Participants/ParticipantsApp/ParticipantsModule';
import { allocationManagerAppModule } from '../Awards/AllocationManager/ParticipantsApp/AllocationManagerModule';

// Global Config
Misc.Settings.progressBar.reverse = true;

// Modules
const appService = AppService as AppServices.AppService;

appService.registerModule(AppServices.NeoModule);
appService.registerModule(NeoReactModule);
appService.registerModule(NotificationServiceModule);
appService.registerModule(appModule);

appService.registerModule(clientsAppModule);
appService.registerModule(awardsAppModule);
appService.registerModule(identityAppModule);
appService.registerModule(transactionsAppModule);
appService.registerModule(participantsAppModule);

appService.registerModule(allocationManagerAppModule);