import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import ParticipantIncentiveSchemeTypeTaxRateLookup from './ParticipantIncentiveSchemeTypeTaxRateLookup';

@NeoModel
export default class PortfolioSettingsLookup extends LookupBase {

    @Attributes.Float()
    @Attributes.Nullable()
    public taxPercentage: number | null = 0;

    public includeBrokerageInTaxDirective: boolean = false;

    public allowTradeCancellation: boolean = false;

    public settlementRequiresTaxDirective: boolean = false;

    public baseCurrencyId: number = 0;

    public showParticipantTradeDetailsButton: boolean = false;

    public brokerDetailsRequiredOnTradeEntry: boolean = false;

    public participantIncentiveSchemeTypeTaxRates = new List(ParticipantIncentiveSchemeTypeTaxRateLookup);

    public secondmentConfirmation: boolean = false;

    public residentCountryId: number = 0;

    public residentCountryName: string = "";

    // Client only properties / methods

    public missingTaxRate: boolean = false;

    public get taxPercentageOrDefault() {
        return this.taxPercentage ?? 0;
    }
}