import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ParticipantContactDetailsLookup extends LookupBase {

    public firstName: string = "";

    public lastName: string = "";

    @Attributes.Observable()
    public correspondenceEmail: string = "";

    public mobileNumber: string = "";

    // Client only properties / methods
}