import { Model } from "@singularsystems/neo-core";
import { ITradeRequestLookup } from "../Models/ITradeRequestLookup";
import ParticipantBrokerDetail from "../Models/ParticipantBrokerDetail";
import UpdateParticipantBrokerDetailCommand from "../Models/UpdateParticipantBrokerDetailCommand";

export class BrokerDetailsHelper {

    public static setBrokerDetails(tradeRequest: ITradeRequestLookup, brokerDetails: Model.PlainTrackedObject<ParticipantBrokerDetail> | null) {

        const command = new UpdateParticipantBrokerDetailCommand();
        command.tradeRequestId = tradeRequest.tradeRequestId;

        if (tradeRequest.participantBrokerDetailId && brokerDetails) {
            command.participantBrokerDetail = ParticipantBrokerDetail.fromJSObject<ParticipantBrokerDetail>(brokerDetails);
        } else {
            if (brokerDetails) {
                tradeRequest.existingBrokerDetails = ParticipantBrokerDetail.fromJSObject<ParticipantBrokerDetail>(brokerDetails);
            }
            command.participantBrokerDetail = new ParticipantBrokerDetail();
        }

        tradeRequest.editingBrokerDetails = command;
    }
}